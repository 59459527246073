import * as React from 'react'
import Layout from '../../components/layout'
import '../../styles/style.scss'

const BenchMarking = () => {
  return (
      <Layout pageTitle='Benchmarking' nextBottomSlideBtn="Benchmarking: Three Step Process" nextBottomSlideBtnLink="/Benchmarking/three-step-process" id="benchmarking">
        <div style={{ display: "flex" }}>
          <div style={{ width: "80%" }}>
            <p>Understanding where and how your organization consumes energy is the first step in successfully embarking on your sustainability journey.</p>
            <br/>
            <h2 style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}>Easy 3-Step Process</h2>
            <p style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}>Southern 360 baselines and benchmarks your energy usage and costs, providing a trusted foundation for shaping your building performance and ESG goals.</p>
          </div>
        </div>
      </Layout>
  )
}

export default BenchMarking